.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  background: #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.my-masonry-grid_column > div > div > a {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.my-masonry-grid_column > div > div {
  background-color: #e6e6e6;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
